import * as ProgressPrimitive from '@radix-ui/react-progress';
import type { FC } from 'react';
import { cn } from '~/utils/cn';

export interface ProgressBarProps {
  correct: number;
  incorrect: number;
  total: number;
  className?: string;
  correctClassName?: string;
  incorrectClassName?: string;
}

export const ProgressBar: FC<ProgressBarProps> = ({
  correct,
  incorrect,
  total,
  className,
  correctClassName,
  incorrectClassName,
}) => {
  const correctWidth = (correct / total) * 100;
  const incorrectWidth = (incorrect / total) * 100;

  return (
    <ProgressPrimitive.Root
      className={cn(
        'bg-secondary relative flex h-1 w-full gap-0.5 overflow-hidden rounded-full transition-all',
        className,
      )}
    >
      <ProgressPrimitive.Indicator
        className={cn(
          'h-full w-0 bg-emerald-500 transition-all',
          correctClassName,
        )}
        style={{ width: `${correctWidth}%` }}
      />
      <ProgressPrimitive.Indicator
        className={cn(
          'h-full w-0 bg-rose-500 transition-all',
          incorrectClassName,
        )}
        style={{ width: `${incorrectWidth}%` }}
      />
    </ProgressPrimitive.Root>
  );
};
